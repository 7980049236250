import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
  Backdrop,
  CircularProgress,
  Collapse,
} from "@mui/material";
import LayoutPage from "../../pages/LayoutPage";
import FlightCard from "./FlightCard";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FilterByDate from "../filterComponent/FilterByDate";
import FilterComponent from "../filterComponent/FilterComponent";
import SearchForm from "../FlightSearch/SearchForm";
import RecommendFilter from "../filterComponent/RecommendFilter";
import {
  fetchFlightResults,
  selectFlightSearchData,
  selectFlightSearchParams,
  selectIsLoadingFlightData,
  selectFlightError,
  setSearchParams,
} from "../../redux/reducers/flightSlice";
import { useLocation } from "react-router-dom";

const FlightResults = () => {
  const flightSearchData = useSelector(selectFlightSearchData);
  const isLoading = useSelector(selectIsLoadingFlightData);
  const error = useSelector(selectFlightError);

  // Destructure the ob and ib flights from flightSearchData
  const outboundFlightData =
    flightSearchData?.response?.specialReturnOffersGroup?.ob || [];
  const returnFlightData =
    flightSearchData?.response?.specialReturnOffersGroup?.ib || [];

  const currentSearchParams = useSelector(selectFlightSearchParams);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  console.log(flightSearchData);
  const [isSearchFormVisible, setIsSearchFormVisible] = useState(false);
  const [uniqueAirlines, setUniqueAirlines] = useState([]);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [filters, setFilters] = useState({
    airline: "All Airlines",
    sortBy: null,
    refundable: "All",
  });

  const location = useLocation();
  const specialReturn = flightSearchData?.response?.specialReturn || false;
  const offers = specialReturn
    ? flightSearchData.response.specialReturnOffersGroup.ob
    : flightSearchData?.response?.offersGroup || [];

  // Extract unique airlines from flight data
  useEffect(() => {
    if (offers && offers.length > 0) {
      const airlineNames = offers.flatMap((offer) =>
        offer.offer.paxSegmentList.map(
          (segment) =>
            segment.paxSegment?.marketingCarrierInfo?.carrierName ||
            "Unknown Airline"
        )
      );
      setUniqueAirlines([...new Set(airlineNames)]);
    }
  }, [offers]);

  // Fetch flights based on URL parameters
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paramsObject = Object.fromEntries(searchParams.entries());
    if (Object.keys(paramsObject).length > 0) {
      dispatch(fetchFlightResults(paramsObject));
    }
  }, [dispatch, location.search]);

  // Toggle search form visibility
  const handleToggleSearchForm = () => {
    setIsSearchFormVisible((prev) => !prev);
  };

  // Handle filter change
  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));
  };

  // Handle date selection
  const handleDateSelect = (date) => {
    const formattedDate = date.toISOString().split("T")[0];
    const updatedSearchParams = {
      ...currentSearchParams,
      request: {
        ...currentSearchParams.request,
        originDest: [
          {
            ...currentSearchParams.request.originDest[0],
            originDepRequest: {
              ...currentSearchParams.request.originDest[0].originDepRequest,
              date: formattedDate,
            },
          },
        ],
      },
    };
    dispatch(setSearchParams(updatedSearchParams));
    dispatch(fetchFlightResults(updatedSearchParams));
  };

  // Filter and sort the flights based on selected filters
  const getFilteredFlights = () => {
    let flights = offers || [];

    // Filter by airline
    if (filters.airline !== "All Airlines") {
      flights = flights.filter((offerItem) =>
        offerItem.offer.paxSegmentList.some(
          (segment) =>
            segment.paxSegment?.marketingCarrierInfo?.carrierName ===
            filters.airline
        )
      );
    }

    // Filter by refundable status
    if (filters.refundable !== "All") {
      flights = flights.filter(
        (offerItem) =>
          (filters.refundable === "Refundable" &&
            offerItem.offer.refundable === true) ||
          (filters.refundable === "Non-refundable" &&
            offerItem.offer.refundable === false)
      );
    }

    // Sort flights by fare
    if (filters.sortBy) {
      flights.sort((a, b) => {
        const baseFareA = a.offer.fareDetailList[0]?.fareDetail?.baseFare || 0;
        const baseFareB = b.offer.fareDetailList[0]?.fareDetail?.baseFare || 0;

        switch (filters.sortBy) {
          case "Cheapest":
            return baseFareA - baseFareB;
          case "Highest":
            return baseFareB - baseFareA;
          default:
            return 0;
        }
      });
    }

    return flights;
  };

  const filteredFlights = getFilteredFlights();

  return (
    <LayoutPage>
      <Grid container style={{ width: "98%", padding: "0" }}>
        <Grid item xs={12}>
          <Box sx={{ height: "auto" }}>
            <Box
              sx={{
                marginTop: "-10px",
                marginBottom: "10px",
                height: "auto",
                justifyContent: "center",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: { xs: "96%", md: "60%" },
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "primary.main",
                  borderBottomRightRadius: "5px",
                  borderBottomLeftRadius: "5px",
                }}
              >
                <Button
                  sx={{
                    height: "4rem",
                    textTransform: "none",
                    width: "100%",
                    fontSize: {
                      xs: "0.875rem",
                      md: "1.300rem",
                    },
                  }}
                  variant="outlined"
                  onClick={handleToggleSearchForm}
                  endIcon={
                    isSearchFormVisible ? (
                      <KeyboardArrowUpIcon sx={{ color: "white" }} />
                    ) : (
                      <KeyboardArrowDownIcon sx={{ color: "white" }} />
                    )
                  }
                >
                  <span style={{ color: "white" }}>Modify search</span>
                </Button>
              </Box>
            </Box>
            <Collapse in={isSearchFormVisible}>
              <Box sx={{ padding: 2, height: "auto" }}>
                <SearchForm searchButtonLabel="Search" />
              </Box>
            </Collapse>
            <Box
              sx={{
                backgroundColor: "rgba(255,255,255,0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
              }}
            >
              <FilterByDate onDateSelect={handleDateSelect} />
            </Box>
          </Box>
        </Grid>

        {/* Flight results section */}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid sx={{ width: "70%" }} item xs={isMobile ? 12 : 9}>
              <Box style={{ height: "100%" }}>
                <Box
                  sx={{
                    width: "100%",
                    minHeight: "36px",
                    backgroundColor: "rgba(255,255,255,0.5)",
                  }}
                >
                  {/* Recommend Filter */}
                  <RecommendFilter
                    flightDataArray={offers || []}
                    onSortFlights={(sortBy) =>
                      handleFilterChange("sortBy", sortBy)
                    }
                  />

                  {/* Filter Component */}
                  <FilterComponent
                    flightDataArray={offers || []}
                    onSortFlights={(sortBy) =>
                      handleFilterChange("sortBy", sortBy)
                    }
                    onFilterByAirline={(airline) =>
                      handleFilterChange("airline", airline)
                    }
                    onFilterByRefundable={(refundStatus) =>
                      handleFilterChange("refundable", refundStatus)
                    }
                  />
                </Box>
                {isLoading ? (
                  <Skeleton variant="text" width={100} height={20} />
                ) : (
                  <Typography
                    alignSelf="baseline"
                    sx={{
                      color: "green",
                      fontSize: {
                        xs: "14px",
                        sm: "18px",
                      },
                    }}
                  >
                    {filteredFlights.length} Flight Results,{" "}
                    {uniqueAirlines.length} Unique Airlines
                  </Typography>
                )}
                <Box>
                  {filteredFlights.map((offerItem, index) => (
                    <div key={offerItem.offer.offerId}>
                      <FlightCard
                        flightData={offerItem.offer}
                        availability={offerItem.offer.seatsRemaining}
                        isLoading={isLoading}
                        onFetchingStart={() => setBackdropOpen(true)}
                        onFetchingComplete={() => setBackdropOpen(false)}
                        showActions={true}
                        isReturn
                        returnFlightData={
                          returnFlightData[index]?.offer || null
                        }
                      />

                      {/* Conditionally render the return flight card */}
                      {returnFlightData[index] && (
                        <FlightCard
                          flightData={returnFlightData[index].offer}
                          hidePrice // Pass this prop to hide the price section in FlightCard for return flights
                          // Additional prop to identify as return flight (optional)
                        />
                      )}
                    </div>
                  ))}
                </Box>
              </Box>
            </Grid>

            {/* Ad space */}
            <Grid sx={{ width: "30%" }} item xs={false} sm={false}>
              <Box style={{ height: "100%", padding: 16 }}>Show ad here</Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Backdrop for loading */}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
        }}
        open={backdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </LayoutPage>
  );
};

export default FlightResults;
